import './bootstrap'
import '../css/app.css'
import VueApexCharts from 'vue3-apexcharts'
import { computed, createApp, h, watch } from 'vue'
import { createInertiaApp, usePage } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import Antd, { notification } from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css'
import { i18nVue, trans } from 'laravel-vue-i18n'
import "vue3-perfect-scrollbar/style.css";
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import '../css/custom.css'
dayjs.extend(relativeTime)

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Codeshaper'


// toast notification
watch(
  () => usePage().props?.toast,
  (toast) => {
    if (toast?.type && toast?.message) {
      notification[toast.type]({ message: toast.message });
    }
  },
  { deep: true }
);

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) =>
    resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })
      .use(VueApexCharts)
      .use(plugin)
      .use(ZiggyVue, Ziggy)
      .use(Antd)
      .use(i18nVue, {
        resolve: async (lang) => {
          const langs = import.meta.glob('../../lang/*.json')
          return await langs[`../../lang/${lang}.json`]()
        }
      })

    app.config.globalProperties.$filters = {
      currency(value) {
        const baseCurrency = props.initialPage.props.auth?.baseCurrency?.symbol || '$';
        const formattedValue = new Intl.NumberFormat('en-US').format(value);
        return `${baseCurrency}${formattedValue}`;
      },
      number(value) {
        const formattedValue = new Intl.NumberFormat('en-US').format(Math.abs(value))
        return formattedValue
      },
      pluralDetector(value, pluralText) {
        try {
          let numberValue = parseInt(value)
          if (numberValue == 0) {
            return pluralText
          } else if (numberValue > 1) {
            return pluralText
          }
          return ''
        } catch {
          return ''
        }
      }
    }

    app.config.globalProperties.translate = (key) => {
      const translation = computed(() => app.config.globalProperties.$t(key));
      return {
        value: translation.value,
        reactive: translation,
        toString: () => translation.value,
      };
    }

    app.directive('demo', {
      mounted(el) {
        const demoMode = props.initialPage.props.demoMode;
        if (!demoMode) {
          el.style.display = 'none';
        }
      }
    })

    window.translate = app.config.globalProperties.translate;

    app.mount(el)
  },
  progress: {
    color: '#4B5563'
  }
})
